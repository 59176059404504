const Apropos = {
  namespaced: true,
  state() {
    return {
      apropos:{
        fr:
          `<p><stong>WATERSIDE</strong> caf&eacute; &amp; <span id="4">kitchen</span>, comme son nom l&rsquo;indique,  
          donne sur les installations du plus grand parc aquatique en Tunisie avec ses piscines, toboggans et son jardin, d&rsquo;un cot&eacute;, et de l&rsquo;autre une belle terrasse en gradin donnant sur une belle vue de l&rsquo;ensemble de <span id="7">port</span> El <span id="8">kantaoui</span>.</p>
          <p>Le <span id="9"><stong>WATERSIDE</strong></span> avec sa carte menu et sa cuisine raffinée, vous réserve un accueil chaleureux et un service personnalisé qui reflète son intérieur convivial.</p>
          <p style="text-align:end;margin-top:2rem;">VENEZ NOUS D&Eacute;GUSTER !</p>`,
        en:
          `<p><stong>WATERSIDE</strong> caf&eacute; &amp; <span id="4">kitchen</span>, as the name suggests, 
          overlooks the facilities of the largest water park in Tunisia with its pools, slides, and garden on one side, and on the other, a beautiful outdoor terrace offering a splendid view of the entirety of <span id="7">port</span> El <span id="8">kantaoui</span>.</p>
          <p><span id="9"><stong>WATERSIDE</strong></span> with its menu and refined cuisine, welcomes you with warmth and personalized service that reflects its friendly interior.</p>
          <p style="text-align:end;margin-top:2rem;">COME AND ENJOY WITH US !</p>`,
      },
      contact: {
        tel: '73-246-050',
        email: 'acqua@acquapalace.com',
      },
    };
  },
  mutations: {
    setapropos(state, value) {
      state.apropos = value;
    },
  },
  getters: {
    getapropos(state, getters, rootState, rootGetters) {
      return state.apropos[rootGetters['lang/getCurrentLang']];
    },
  },
};

export default Apropos;
