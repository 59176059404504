import { createStore } from 'vuex';
import menu from './modules/Menu';
import apropos from './modules/Apropos';
import gallerie from './modules/Gallerie';
import home from './modules/Home';
import lang from './modules/langage';

export default createStore({
  state() {
    return {
      isLoading: false,
    };
  },
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    Menu: menu,
    Apropos: apropos,
    Gallerie: gallerie,
    Home: home,
    lang: lang,
  },
});
