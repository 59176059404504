export const routes = [
  {
    name: 'client',
    path: '/',
    redirect: { name: '' },
    component: () =>
      import(/* webpackChunkName: "welcome"*/ '@/views/client/Client.vue'),
    children: [
      {
        name: '',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "welcome"*/ '@/views/client/Accueil/Accueil.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2024-01-29',
            changefreq: 'weekly',
            priority: 1.0,
          },
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: '',
        component: () => import('@/views/client/Accueil/Accueil.vue'),
      },
    ],
  },
];

export default routes;
