import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import { routes } from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery/dist/jquery.js';
import 'popper.js/dist/esm/popper.js';
import 'animate.css/animate.min.css';
import VTouchEvt from 'vue3-touch-events';
import { VueCookieNext } from 'vue-cookie-next';
import VueAgile from 'vue-agile';

const app = createApp(App);

app.use(routes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
});
VueCookieNext.config({ expire: '7d' });
app
  .use(VueCookieNext)
  .use(store)
  .use(router)
  .use(VTouchEvt, { disableClick: true })
  .use(VueAgile)
  .mount('#app');
