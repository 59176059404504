const Gallerie = {
  namespaced: true,
  state() {
    return {
      Gallerie: [
        {
          id: 0,
          path: require('@/assets/4X2A7871(4).jpeg'),
        },
        {
          id: 1,
          path: require('@/assets/water side -95(2).jpeg'),
        },
        {
          id: 2,
          path: require('@/assets/4X2A7837.jpeg'),
        },
        {
          id: 3,
          path: require('@/assets/water side -111.jpeg'),
        },
        {
          id: 4,
          path: require('@/assets/water side -112.jpeg'),
        },
        {
          id: 5,
          path: require('@/assets/DSC_1828.jpeg'),
        },
        {
          id: 6,
          path: require('@/assets/DSC_1829.jpeg'),
        },
        {
          id: 7,
          path: require('@/assets/water side -113.jpeg'),
        },
        {
          id: 8,
          path: require('@/assets/IMG-V_115625.jpeg'),
        },
      ],
    };
  },
};

export default Gallerie;
