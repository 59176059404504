import { VueCookieNext } from 'vue-cookie-next';

function getNavLang() {
  let navLang = navigator.language.split('-')[0];
  let availableLanguages = ['fr', 'en'];
  return availableLanguages.includes(navLang) === true ? navLang : 'en';
}

function ImportFlags() {
  let flagsContext = require.context('@/assets', false, /..flag\.webp/);
  const obj = {};
  flagsContext.keys().forEach((key) => {
    const countryCode = key
      .split('./')
      .pop()
      .substring(0, key.length - 11);
    obj[countryCode] = flagsContext(key);
  });
  return obj;
}

const lang = {
  namespaced: true,
  state() {
    const actLang = getNavLang();
    return {
      currentLang:
        VueCookieNext.getCookie('WatersideLang') == null
          ? actLang
          : VueCookieNext.getCookie('WatersideLang'),
      currentFlag: require('@/assets/' + actLang + 'flag.webp'),
      flags: ImportFlags(),
    };
  },
  mutations: {
    setLang(state, val) {
      VueCookieNext.setCookie('WatersideLang', val);
      state.currentLang = val;
    },
  },
  getters: {
    getCurrentLang(state) {
      return state.currentLang;
    },
    getCurrentFlag(state) {
      return state.flags[state.currentLang];
    },
  },
  actions: {},
};
export default lang;
