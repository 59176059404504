const Home = {
  namespaced: true,
  state() {
    return {
      timetxt: {
        fr: 'Nous sommes ouverts tous les jours de 8h à 23h59',
        en: 'we are open everyday from 8AM to 11:59PM',
      },
    };
  },
  mutations: {
    settimeTxt(state, value) {
      state.prices = value;
    },
  },
  getters: {
    gettimeTxt(state, getters, rootState, rootGetters) {
      return state.timetxt[rootGetters['lang/getCurrentLang']];
    },
  },
};

export default Home;
